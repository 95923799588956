import React, { useState, useEffect, useCallback } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ScrollToTop = props => {
  const [isShow, setShow] = useState(false);
  useEffect(() => {
    function scrollFunction() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  const scrollToTop = useCallback(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);
  return (
    <>
      <div className="scroll-to-top shadow border" onClick={scrollToTop}>
        <ArrowUpwardIcon />
      </div>
      <style jsx>{`
        .scroll-to-top {
          display: ${isShow ? "block" : "none"};
          position: fixed;
          bottom: calc(70px + env(safe-area-inset-bottom));
          right: 20px;
          background-color: #fff;
          color: #f58220;
          cursor: pointer;
          padding: 8px;
          border-radius: 50%;
          animation: slideUp 0.3s;
          z-index: 9999;
        }

        @keyframes slideUp {
          0% {
            bottom: 0;
          }
          100% {
            bottom: 60px;
          }
        }
      `}</style>
    </>
  );
};

export default React.memo(ScrollToTop);
